import { css } from '@emotion/react'
import React, { useState, useEffect, Fragment } from 'react'
// import styled from '@emotion/styled'
import {
    Grid,
    Typography,
    Box
} from '@material-ui/core';
import {
    CardElevation,
    ButtonDegrade,
    Oferta,
    // CardProgres
} from '@findep/mf-landings-core';
import CarouselImg from '../img/CarouselImg.compilable'
import { navigate } from "../../helpers/queryNavigate"
import FelicidadesApp from '../elements/FelicidadesApp.compilable';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { CataloguesService } from '../../services/LandingV4'
import formatNumber from '../../helpers/formatNumber'
import NameCompany from '../../helpers/NameCompany'
import { getPeriodPlural } from '../../helpers/period'
// import {
//     getTaskIcon,
//     getTaskStatusIcon,
//     getTaskUrl,
//     getTaskActionText,
//     getSpecialStatusCase,
//     getOrderArray,

// } from '../helpers/tubeTask'

const Felicidades = ({ companyName }) => {

    const [flujo, setFlujo] = useState({});
    const [survey, setSurvey] = useState('');
    const [congratulations, setCongratulations] = useState('')
    const [nameCompany, setNameCompany] = useState(NameCompany(companyName));
    const [fecha, setFecha] = useState('');
    const [pensionados, setPensionados] = useState(false);
    const [revolvente, setRevolvente] = useState(false);
    const [creditApplicationId, setCreditApplicationId] = useState('');



    useEffect(() => {
        let localFlujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let localSurvey = sessionStorage.getItem('survey') || {}
        let congratulaionMessage

        setCreditApplicationId(localFlujo.creditApplicationId)
        /** */
        let urlPathname = window.location.pathname

        if (urlPathname.indexOf('pensionados') >= 0) {
            setPensionados(true)
        } else {
            setPensionados(false)
        }

        if (urlPathname.indexOf('revolvente') >= 0) {
            setRevolvente(true)
        }

        // setFlujo(localFlujo)

        async function getdata() {

            const catalogos = new CataloguesService()
            const catData = await catalogos.getSurveys(localFlujo.creditApplicationId)

            if (catData.status === 200) {
                localSurvey = 'true'
                setSurvey(localSurvey)
            }

            const data = await catalogos.getCongratulations(localFlujo.creditApplicationId)
            congratulaionMessage = data.data

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'Felicidades',
                'eventCategory': 'RenovacionesDigital',
                'eventAction': 'felicidades-View',
                'eventLabel': undefined,
                'creditAppID': localFlujo.creditApplicationId
            })

            setCongratulations(congratulaionMessage)
            const { forceRedirect } = data.data
            if (forceRedirect !== null && forceRedirect !== undefined) {
                navigate(forceRedirect)
            }
        }
        try {
            getdata()
        } catch (e) {
            console.error(e)
        }

        var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
        let f = new Date(localFlujo?.preOferta?.oferta?.nextPayment)
        let arrFecha = `${f.getDate()}-${meses[f.getMonth()]}-${f.getFullYear()}`
        setFecha(arrFecha)

        let eventLabel = `${congratulations && congratulations ? congratulations?.amount : 0}_${congratulations && congratulations ? congratulations?.payment : 0}_${congratulations ? congratulations ? `${congratulations?.terms}${getPeriodPlural(congratulations?.period)} ` : '' : ''}`
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'Felicidades',
            'eventCategory': 'Renovaciones',
            'eventAction': 'step4-Felicidades',
            'eventLabel': eventLabel,
            'creditAppID': creditApplicationId
        })

    }, [])

    const Texleyenda = css`
    text-align: right;
    padding-right: 60px;
  `

    const TextHeader = css`
    color: primary;
    text-align: center;
    font-weight: bold;
    font-size: 3em;
  `
    const textSecondHeader = css`
    text-align: center;
    font-size: 2em;
    `
    const textMessage = css`
        font-size: 1rem;
    `
    const styleTextEncuesta = css`
    padding: 1rem;
    color: rgba(0, 0, 0, 0.87);
`
    const rootPage = css`
    padding-left: 1rem;
    padding-right: 1rem;
`

    // if (pensionados || revolvente) {
    //     props = [
    //         { icono: ArrowForwardIcon, titulo: 'Monto:', texto: `${formatNumber(flujo ? flujo.preOferta ? flujo.preOferta.oferta.amount : 0 : 0)}` },
    //         { icono: ArrowForwardIcon, titulo: 'Pago:', texto: `${formatNumber(flujo ? flujo.preOferta ? flujo.preOferta.oferta.payment : 0 : 0)}` },
    //         { icono: ArrowForwardIcon, titulo: 'Plazo:', texto: flujo ? flujo.preOferta ? `${flujo.preOferta.oferta.terms} ${getPeriodPlural(flujo?.preOferta?.oferta?.period)} ` : '' : '' },
    //         { icono: ArrowForwardIcon, titulo: 'Nota: ', texto: 'Consulta la fecha de pago en tu paquete de disposición' },
    //     ]
    // } else {
    let props = [
        { icono: ArrowForwardIcon, titulo: 'Monto:', texto: `${formatNumber(congratulations ? congratulations ? congratulations?.amount : 0 : 0)}*` },
        { icono: ArrowForwardIcon, titulo: 'Pago:', texto: `${formatNumber(congratulations ? congratulations ? congratulations?.estimatedPayment : 0 : 0)}` },
        { icono: ArrowForwardIcon, titulo: 'Plazo:', texto: congratulations ? congratulations ? `${congratulations?.terms} ${getPeriodPlural(congratulations?.period)} ` : '' : '' },
        // { icono: ArrowForwardIcon, titulo: 'Primer pago:', texto: flujo ? flujo.preOferta ? `${fecha}` : '' : '' },
        { icono: ArrowForwardIcon, titulo: 'Nota: ', texto: 'Consulta la fecha de pago en tu paquete de disposición' },
    ]
    // }

    const propsSucursal = [
        { icono: ArrowForwardIcon, titulo: 'Sucursal: ', texto: `${congratulations?.branch?.name}  ${congratulations?.branch?.city ? congratulations?.branch?.city : ''} Col. ${congratulations?.branch?.neighborhood} ${congratulations?.branch?.address} ${congratulations?.branch?.postalCode} ${congratulations?.branch?.state}`, sizeFontLi: '14px' }
    ]



    return (
        <div>
            <Grid container direction="column" justify="center" alignItems="center" css={rootPage}>
                <br />
                <br />
                <Typography color='primary'>
                    <p css={TextHeader}>¡Felicidades!</p>
                </Typography>
                <br />
                <Oferta datos={props} colorOne="#FF9F01" colorTwo="#FA4616" />
            </Grid>
            <p css={Texleyenda}>*Sujeto a aprobación de crédito</p>
            <Grid container direction="column" justify="center" alignItems="center" css={rootPage}>
                <Typography css={styleTextEncuesta}>
                    <p css={textMessage}>{congratulations.customerMessage}.</p>
                    <br /><br />
                    {
                        congratulations.branch ? <Oferta datos={propsSucursal} colorOne="#FF9F01" colorTwo="#FA4616" /> : null
                    }
                </Typography>
                <br />
                <CardElevation>
                    <Typography>
                        <p style={{ fontSize: '1rem' }}>¡Ahorra tiempo! Maneja tu cuenta en donde te encuentres. ¡Descarga nuestra aplicación hoy!</p>
                    </Typography>

                    <FelicidadesApp company={companyName} />

                </CardElevation>
                {
                        /* survey !== 'true' && */ (
                        <Fragment>
                            <Typography css={styleTextEncuesta} align="center">
                                ¿Nos ayudarías a contestar una breve encuesta para mejorar nuestro servicio?
                            </Typography>
                            <Box display="flex" justifyContent="flex-end">
                                <ButtonDegrade
                                    onClick={
                                        () => navigate(`/encuesta/`)
                                    }
                                    textButton="CONTESTAR ENCUESTA"
                                />
                            </Box>
                        </Fragment>
                    )
                }
                <CarouselImg filename="1.svg" company={companyName} />
                <br />
            </Grid>
        </div >

    )
}

export default Felicidades